<template>
  <v-app>
    <v-main>
      <keyword-table></keyword-table>
    </v-main>
  </v-app>
</template>

<script>
  import { mapActions } from "vuex";
  import KeywordTable from "@/components/KeywordTable";
  export default {
    components: { KeywordTable },
    data: () => ({
      search: "",
    }),
    methods: { ...mapActions(["getKeywords"]) },
    mounted() {
      this.getKeywords();
    },
  };
</script>