import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

import wretch from "wretch";
import handleResponse from "@/assets/handle-response";

export default new Vuex.Store({
  state: {
    keywords: []
  },
  mutations: {},
  actions: {
    getKeywords({ state }) {
      wretch("/api/get-keywords")
        .get()
        .json()
        .then(handleResponse)
        .then(keywords => (state.keywords = keywords));
    }
  },
  modules: {}
});
