<template>
  <div>
    <v-data-table
      :mobile-breakpoint="0"
      :items="keywords"
      :headers="headers"
      :search="search"
      hide-default-footer
      hide-default-header
      dense
      :items-per-page="15"
      show-expand
    >
      <template #top="{ pagination, options }">
        <table-top
          :search="search"
          :pagination="pagination"
          @prev-page="options.page--"
          @next-page="options.page++"
          @update-search="(v) => (search = v)"
        ></table-top>
      </template>

      <template #[`item.keyword`]="{ item }">
        <div v-html="item.keyword" class="font-weight-bold"></div>
      </template>

      <template #expanded-item="{ item, headers }">
        <td :colspan="headers.length">
          <v-container>
            <div v-html="item.rule"></div>
          </v-container>
        </td>
      </template>
    </v-data-table>
  </div>
</template>

<script>
  import { mapState } from "vuex";
  import TableTop from "./TableTop.vue";
  export default {
    components: { TableTop },
    data: () => ({
      headers: [{ text: "Keyword", value: "keyword" }],
      search: "",
    }),
    computed: {
      ...mapState(["keywords"]),
    },
  };
</script>