<template>
  <v-toolbar class="mb-4">
    <v-text-field
      dense
      v-model="searchValue"
      label="Search"
      outlined
      class="mt-6"
      clear-icon="mdi-close-circle"
      @keydown.esc="searchValue = ''"
      clearable
    ></v-text-field>
    <v-spacer></v-spacer>
    <v-btn
      icon
      :disabled="pagination.page == 1"
      @click="$emit('prev-page')"
      small
    >
      <v-icon> mdi-chevron-left </v-icon>
    </v-btn>
    {{ pagination.page }} of {{ pagination.pageCount }}
    <v-btn
      icon
      :disabled="pagination.page == pagination.pageCount"
      @click="$emit('next-page')"
      small
    >
      <v-icon> mdi-chevron-right </v-icon>
    </v-btn>
  </v-toolbar>
</template>

<script>
  export default {
    props: ["search", "pagination"],
    computed: {
      searchValue: {
        get() {
          return this.search;
        },
        set(v) {
          this.$emit("update-search", v);
        },
      },
    },
  };
</script>